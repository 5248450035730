.container {
  width: 100%;
  height: 100%;
  .siteRoute {
    background-color: #111119;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 120px;
    overflow-x: hidden;

    @media only screen and (max-width: 1000px) {
      padding: 0 60px;
    }
    @media only screen and (max-width: 700px) {
      padding: 0 30px;
    }
  }
  .logoCircle {
    width: 344px;
    height: 344px;
    border-radius: 50%;
    background-color: #13131d;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -17%;
    left: 6%;
    @media only screen and (max-width: 1650px) {
      left: 7%;
    }
    @media only screen and (max-width: 1630px) {
      width: 310px;
      height: 310px;
      left: 6.5%;
    }
    @media only screen and (max-width: 1500px) {
      width: 266px;
      height: 266px;
      left: 8%;
    }
    @media only screen and (max-width: 860px) {
      left: -2%;
      display: none;
    }
  }
  .main {
    background-color: #111119;
    font-family: Poppins;
    max-width: 1440px;
    width: 100%;
    @media only screen and (max-width: 1440px) {
      padding: 0 0;
    }
    .component {
      min-height: 92.5vh;
      width: 100%;
      position: relative;
      padding-bottom: 80px;
    }
    .demoComponentContainer {
      height: 90vh;
      width: 100%;
      position: relative;
    }
    .mobileMenu {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background-color: #13131d;
      width: 100%;
      height: 100vh;
      transition: all 0.4s ease-in-out;

      .closeIcon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding: 15px 15px;

        .close {
          height: 29px;
          width: 29px;
        }
      }

      .mobileMenuArea {
        padding-top: 100px;
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;

        .action {
          margin: 37px 25px;
          cursor: pointer;
          font-size: 28px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.93;
          letter-spacing: 0.88px;
          color: #fff;
          -webkit-text-stroke: 0.4px #fff;
        }

        .active {
          color: #97f5e0;
          -webkit-text-stroke: 0.4px #97f5e0;
        }
        .green {
          padding: 16px 24px;
          border-radius: 10px;
          border: solid 1px #97f5e0;
        }
      }
    }

    .mobileMenuOFF {
      top: -100%;
      position: fixed;
      z-index: -10;
    }

    .mobileMenuON {
      position: fixed;
      top: 0;
      z-index: 100;
    }

    .navbar {
      padding: 30px 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: fixed;
      transition: all 0.15s linear;
      margin-bottom: 40px;
      z-index: 11;
      @media only screen and (max-width: 1630px) {
        padding: 27px 180px;
        margin-bottom: 36px;
      }
      @media only screen and (max-width: 1500px) {
        padding: 24px 160px;
        margin-bottom: 32px;
      }
      @media only screen and (max-width: 1300px) {
        padding: 21px 140px;
        margin-bottom: 28px;
      }
      @media only screen and (max-width: 1100px) {
        padding: 18px 120px;
        margin-bottom: 24px;
      }
      @media only screen and (max-width: 900px) {
        padding: 15px 100px;
        margin-bottom: 22px;
      }
      @media only screen and (max-width: 860px) {
        padding: 15px;
        margin-bottom: 20px;
      }

      .menuBar {
        display: none;
        @media only screen and (max-width: 860px) {
          display: block;
          cursor: pointer;
        }
      }

      .actionArea {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        @media only screen and (max-width: 860px) {
          display: none;
        }
        .action {
          margin: 0 24px;
          cursor: pointer;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.44;
          letter-spacing: 0.57px;
          color: #fff;
          -webkit-text-stroke: 0.4px #fff;
          @media only screen and (max-width: 1630px) {
            margin: 0 22px;
          }
          @media only screen and (max-width: 1500px) {
            margin: 0 20px;
          }
          @media only screen and (max-width: 1200px) {
            margin: 0 18px;
          }
          @media only screen and (max-width: 1000px) {
            margin: 0 16px;
          }
        }
        .active {
          color: #97f5e0;
          -webkit-text-stroke: 0.4px #97f5e0;
        }
        .green {
          padding: 5px 18px;
          border-radius: 9px;
          border: solid 1px #97f5e0;
        }
      }
    }
    .sticky {
      display: flex;
      justify-content: space-between;
      background-color: #1a1a22;
      padding: 20px 200px;
      transition: all 0.15s linear;
      @media only screen and (max-width: 1630px) {
        padding: 18px 180px;
      }
      @media only screen and (max-width: 1500px) {
        padding: 16px 160px;
      }
      @media only screen and (max-width: 1300px) {
        padding: 14px 140px;
      }
      @media only screen and (max-width: 1100px) {
        padding: 12px 120px;
      }
      @media only screen and (max-width: 900px) {
        padding: 10px 100px;
      }

      @media only screen and (max-width: 860px) {
        padding: 10px 15px;
        margin-bottom: 20px;
      }
    }

    .section1 {
      padding: 150px 0 250px 0;
      margin-top: 100px;
      margin-bottom: 60px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: 1500px) {
        padding: 130px 0 200px 0;
      }
      @media only screen and (max-width: 1300px) {
        padding: 120px 0 175px 0;
      }
      @media only screen and (max-width: 1100px) {
        padding: 110px 0 150px 0;
      }
      @media only screen and (max-width: 900px) {
        padding: 100px 0 125px 0;
      }

      @media only screen and (max-width: 860px) {
        padding: 420px 0 100px 0;
      }
      @media only screen and (max-width: 550px) {
        padding: 370px 0 100px 0;
      }
      @media only screen and (max-width: 466px) {
        padding: 340px 0 100px 0;
      }
      @media only screen and (max-width: 425px) {
        padding: 300px 0 100px 0;
      }

      .chairImage {
        width: 700px;
        position: absolute;
        left: 50%;
        z-index: 1;
        @media only screen and (max-width: 1781px) {
          left: 46%;
        }
        @media only screen and (max-width: 1630px) {
          width: 630px;
          left: 47%;
        }
        @media only screen and (max-width: 1500px) {
          width: 560px;
        }
        @media only screen and (max-width: 1110px) {
          width: 490px;
        }
        @media only screen and (max-width: 1000px) {
          left: 43%;
        }
        @media only screen and (max-width: 860px) {
          width: 450px;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          margin-top: 20px;
        }
        @media only screen and (max-width: 550px) {
          width: 370px;
        }
        @media only screen and (max-width: 466px) {
          width: 350px;
        }
        @media only screen and (max-width: 425px) {
          width: 315px;
        }
      }
    }

    .section2 {
      padding-bottom: 180px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media only screen and (max-width: 1630px) {
        padding-bottom: 170px;
      }
      @media only screen and (max-width: 1500px) {
        padding-bottom: 160px;
      }
      @media only screen and (max-width: 1300px) {
        padding-bottom: 150px;
      }
      @media only screen and (max-width: 1100px) {
        padding-bottom: 140px;
      }
      @media only screen and (max-width: 900px) {
        padding-bottom: 120px;
      }

      @media only screen and (max-width: 860px) {
        padding-bottom: 100px;
        flex-direction: column;
      }
      .section2image {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        @media only screen and (max-width: 1630px) {
          height: 540px;
        }
        @media only screen and (max-width: 1500px) {
          height: 480px;
        }
        @media only screen and (max-width: 1300px) {
          height: 420px;
        }
        @media only screen and (max-width: 1100px) {
          height: 360px;
        }
        @media only screen and (max-width: 900px) {
          height: 300px;
        }
        @media only screen and (max-width: 860px) {
          height: 500px;
          margin-bottom: 50px;
        }
        @media only screen and (max-width: 550px) {
          height: 100%;
          margin-bottom: 40px;
        }
      }
    }

    .section3 {
      padding: 50px 0;
      display: flex;
      height: 500px;
      justify-content: space-between;
      align-items: center;
      position: relative;
      @media only screen and (max-width: 1630px) {
        height: 630px;
      }
      @media only screen and (max-width: 1500px) {
        height: 560px;
      }
      @media only screen and (max-width: 1300px) {
        height: 490px;
      }
      @media only screen and (max-width: 1100px) {
        height: 420px;
      }
      @media only screen and (max-width: 900px) {
        height: 350px;
      }

      @media only screen and (max-width: 860px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        height: 480px;
        margin-bottom: 80px;
      }
      @media only screen and (max-width: 646px) {
        height: 490px;
      }
      @media only screen and (max-width: 490px) {
        height: 520px;
      }

      .backgroundCircle {
        width: 600px;
        height: 600px;
        border-radius: 50%;
        background-color: #13131d;
        position: absolute;
        right: 0;
        top: -7%;
        @media only screen and (max-width: 1630px) {
          width: 650px;
          height: 650px;
        }
        @media only screen and (max-width: 1500px) {
          width: 575px;
          height: 575px;
        }
        @media only screen and (max-width: 1300px) {
          width: 500px;
          height: 500px;
        }
        @media only screen and (max-width: 1100px) {
          width: 425px;
          height: 425px;
        }
        @media only screen and (max-width: 900px) {
          width: 350px;
          height: 350px;
        }
        @media only screen and (max-width: 860px) {
          width: 430px;
          height: 430px;
          right: 10%;
        }
        @media only screen and (max-width: 550px) {
          height: 400px;
          width: 400px;
        }
        @media only screen and (max-width: 466px) {
          height: 380px;
          width: 380px;
        }
        @media only screen and (max-width: 425px) {
          height: 360px;
          width: 360px;
        }
        .section3image {
          position: absolute;
          top: 5%;
          right: -15%;
          height: 490px;
          @media only screen and (max-width: 1630px) {
            height: 440px;
          }
          @media only screen and (max-width: 1500px) {
            height: 390px;
          }
          @media only screen and (max-width: 1300px) {
            height: 340px;
          }
          @media only screen and (max-width: 1100px) {
            height: 290px;
          }
          @media only screen and (max-width: 900px) {
            height: 240px;
          }

          @media only screen and (max-width: 860px) {
            height: 320px;
            right: -30%;
          }
          @media only screen and (max-width: 550px) {
            right: -40%;
          }
          @media only screen and (max-width: 466px) {
            right: -50%;
          }
          @media only screen and (max-width: 425px) {
            right: -60%;
          }
        }
      }
    }

    .section4 {
      padding: 100px 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media only screen and (max-width: 1630px) {
        padding: 90px 40px;
      }
      @media only screen and (max-width: 1500px) {
        padding: 80px 40px;
      }
      @media only screen and (max-width: 1300px) {
        padding: 70px 0;
      }
      @media only screen and (max-width: 1100px) {
        padding: 60px 0;
      }
      @media only screen and (max-width: 900px) {
        padding: 30px 0;
      }
      @media only screen and (max-width: 860px) {
        flex-direction: column;
      }

      .activityContainer {
        margin: 0 20px 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        font-stretch: normal;
        font-style: normal;
        text-align: center;
        z-index: 10;

        .activityImage {
          height: 112px;
          width: 112px;
          @media only screen and (max-width: 1630px) {
            height: 102px;
            width: 102px;
          }
          @media only screen and (max-width: 1500px) {
            height: 92px;
            width: 92px;
          }
          @media only screen and (max-width: 1300px) {
            height: 82px;
            width: 82px;
          }
          @media only screen and (max-width: 1100px) {
            height: 72px;
            width: 72px;
          }
          @media only screen and (max-width: 900px) {
            height: 62px;
            width: 62px;
          }
          @media only screen and (max-width: 860px) {
            height: 112px;
            width: 112px;
          }
        }

        .activityTitle {
          -webkit-text-stroke: 1px #fff;
          font-size: 16px;
          font-weight: 500;
          line-height: 3.63;
          letter-spacing: 2.16px;
          color: #fff;
        }

        .activitySubtitle {
          margin: 10px 0;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.11;
          letter-spacing: normal;
          font-family: Roboto;
          color: #c9cbe1;
        }
      }
    }
    .section5 {
      padding: 100px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 100px;

      .lightBackground {
        background-color: #13131d;
        position: absolute;
        width: 100%;
        height: 1000px;
        margin-top: -60px;

        @media only screen and (max-width: 860px) {
          height: 720px;
        }
        @media only screen and (max-width: 400px) {
          height: 800px;
        }
      }

      @media only screen and (max-width: 1630px) {
        padding: 90px 0;
      }
      @media only screen and (max-width: 1500px) {
        padding: 80px 0;
      }
      @media only screen and (max-width: 1300px) {
        padding: 70px 0;
      }
      @media only screen and (max-width: 1100px) {
        padding: 60px 0;
      }
      @media only screen and (max-width: 900px) {
        padding: 50px 0;
      }
      @media only screen and (max-width: 860px) {
        padding: 50px 30px;
      }
      .codeArea {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px 0;

        @media only screen and (max-width: 860px) {
          display: none;
        }
        .terminal {
          width: 900px;
          height: 450px;
          z-index: 1;
          overflow: hidden;
        }
        .platformsArea {
          z-index: 5;
          display: flex;
          position: absolute;
          top: 4%;
          right: 6%;
          .platform {
            margin: 2px 16px;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.2px;
            color: #fff;
            cursor: pointer;
            @media only screen and (max-width: 1500px) {
              margin: 2px 14px;
            }
          }

          .selectedPlatform {
            margin: 2px 18px;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.2px;
            color: #91ffc2;
            cursor: pointer;
            @media only screen and (max-width: 1630px) {
              margin: 2px 16px;
            }
            @media only screen and (max-width: 1500px) {
              margin: 2px 14px;
            }
          }
        }
        .codeScripts {
          position: absolute;
          top: 15%;
          z-index: 5;
          height: 360px;
          width: 750px;
          padding: 0 20px;
          overflow: auto;
          padding-right: 40px;
          .blankSpace {
            overflow: auto;
            &::-webkit-scrollbar-thumb:vertical {
              background-color: #fff;
              border-radius: 6px;
            }
            &::-webkit-scrollbar-track:vertical {
              background: #13131d;
              border-radius: 6px;
            }
            &::-webkit-scrollbar-thumb:horizontal {
              background-color: #0f0f18;
            }
            &::-webkit-scrollbar-track:horizontal {
              background: #0f0f18;
            }
          }
          &::-webkit-scrollbar-thumb:vertical {
            background-color: #fff;
            border-radius: 6px;
          }
          &::-webkit-scrollbar-track:vertical {
            background: #13131d;
            border-radius: 6px;
          }
          &::-webkit-scrollbar-thumb:horizontal {
            background-color: #0f0f18;
          }
          &::-webkit-scrollbar-track:horizontal {
            background: #0f0f18;
          }
        }
      }
      .mobileTerminal {
        display: none;
        margin: 30px auto;
        text-align: center;
        @media only screen and (max-width: 860px) {
          display: block;
        }
        .mobileTerminalImage {
          width: 100%;
          height: 100%;
          margin: 40px 0 20px 0;
          transform: scale(1.5);
        }
      }
      .centerButtonArea {
        width: 100%;
        margin: 0 auto;
        background-color: red;
        text-align: center;
      }
    }

    .logoCircle {
      width: 344px;
      height: 344px;
      border-radius: 50%;
      background-color: #13131d;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -17%;
      left: 6%;
      @media only screen and (max-width: 1650px) {
        left: 7%;
      }
      @media only screen and (max-width: 1630px) {
        width: 310px;
        height: 310px;
        left: 6.5%;
      }
      @media only screen and (max-width: 1500px) {
        width: 266px;
        height: 266px;
        left: 8%;
      }
      @media only screen and (max-width: 860px) {
        left: -2%;
        display: none;
      }
    }
    .bodyCircle {
      width: 720px;
      height: 720px;
      background-color: #13131d;
      border-radius: 50%;
      position: absolute;
      top: 58%;
      right: -11%;
      transform: translateY(-50%);
      overflow: hidden;

      @media only screen and (max-width: 1650px) {
        top: 62%;
      }
      @media only screen and (max-width: 1630px) {
        width: 650px;
        height: 650px;
        top: 65%;
      }
      @media only screen and (max-width: 1500px) {
        width: 580px;
        height: 580px;
        top: 58%;
      }
      @media only screen and (max-width: 1300px) {
        width: 510px;
        height: 510px;
      }
      @media only screen and (max-width: 1100px) {
        width: 440px;
        height: 440px;
      }
      @media only screen and (max-width: 930px) {
        width: 370px;
        height: 370px;
        top: 55%;
      }
      @media only screen and (max-width: 900px) {
        top: 59%;
      }

      @media only screen and (max-width: 860px) {
        width: 420px;
        width: 420px;
        top: 40%;
      }
      @media only screen and (max-width: 550px) {
        width: 360px;
        height: 360px;
      }
      @media only screen and (max-width: 466px) {
        width: 340px;
        height: 340px;
      }
      @media only screen and (max-width: 425px) {
        width: 310px;
        height: 310px;
      }
    }

    .textArea {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-stretch: normal;
      font-style: normal;
      z-index: 10;
      @media only screen and (max-width: 860px) {
        width: 100%;
      }
      .bigTitle {
        width: 600px;
        margin: 36px 50px 19px 0;
        font-size: 66px;
        text-align: left;
        font-weight: 500;
        line-height: 0.94;
        letter-spacing: 1.3px;
        color: #fff;
        @media only screen and (max-width: 1630px) {
          margin: 32px 40px 17px 0;
        }
        @media only screen and (max-width: 1500px) {
          margin: 28px 30px 15px 0;
        }
        @media only screen and (max-width: 1300px) {
          width: 480px;
          margin: 24px 30px 13px 0;
        }
        @media only screen and (max-width: 1100px) {
          width: 400px;
          margin: 20px 25px 11px 0;
        }
        @media only screen and (max-width: 900px) {
          width: 285px;
          margin: 16px 20px 10px 0;
        }
        @media only screen and (max-width: 860px) {
          font-size: 45px;
          width: 100%;
        }
      }

      .smallTitle {
        width: 580px;
        margin: 0 0 24px 2px;
        text-align: left;
        font-size: 50px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1.39px;
        color: #fff;

        @media only screen and (max-width: 1630px) {
          margin: 0 0 22px;
        }
        @media only screen and (max-width: 1500px) {
          width: 480px;
          margin: 0 0 18px;
        }
        @media only screen and (max-width: 1300px) {
          width: 430px;
          margin: 0 0 16px;
        }
        @media only screen and (max-width: 1100px) {
          width: 380px;
          margin: 0 0 14px;
        }
        @media only screen and (max-width: 900px) {
          margin: 0 0 12px;
        }

        @media only screen and (max-width: 860px) {
          font-size: 31px;
          width: 100%;
          margin-top: 50px;
        }
      }

      .subtitle {
        width: 550px;
        margin: 24px 23px 10px 0;
        text-align: start;
        -webkit-text-stroke: 0.3px #d3d5e7;
        font-size: 22px;
        font-weight: 300;
        line-height: 1.3;
        letter-spacing: 0.6px;
        color: #c9cbe1;
        font-family: Roboto;

        span {
          color: #fff;
        }
        @media only screen and (max-width: 1630px) {
          margin: 20px 20px 9px 0;
        }
        @media only screen and (max-width: 1500px) {
          width: 480px;
          margin: 18px 18px 8px 0;
        }
        @media only screen and (max-width: 1300px) {
          margin: 16px 16px 7px 0;
          width: 430px;
        }
        @media only screen and (max-width: 1100px) {
          width: 380px;
          margin: 14px 14px 6px 0;
        }
        @media only screen and (max-width: 900px) {
          margin: 12px 12px 5px 0;
        }

        @media only screen and (max-width: 860px) {
          width: 100%;
        }
      }
      .centerTitle {
        text-align: center;
        margin: 15px auto;
        width: 750px;
        font-size: 50px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1.39px;
        color: #fff;

        @media only screen and (max-width: 860px) {
          font-size: 31px;
          width: 100%;
        }
      }
      .centerSubtitle {
        margin: 15px auto;
        width: 750px;
        -webkit-text-stroke: 0.3px #d3d5e7;
        font-size: 22px;
        font-weight: 300;
        line-height: 1.3;
        letter-spacing: 0.6px;
        color: #c9cbe1;
        font-family: Roboto;
        text-align: center;

        @media only screen and (max-width: 860px) {
          width: 100%;
        }
      }

      .buttonLink {
        width: inherit;
      }
      .button {
        margin-top: 30px;
        height: 68px;
        padding: 0 64px;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        line-height: normal;
        text-align: center;
        color: #fff;
        letter-spacing: 2.4px;

        @media only screen and (max-width: 1630px) {
          margin-top: 27px;
        }
        @media only screen and (max-width: 1500px) {
          margin-top: 24px;
        }
        @media only screen and (max-width: 1300px) {
          margin-top: 21px;
        }
        @media only screen and (max-width: 1100px) {
          margin-top: 18px;
        }
        @media only screen and (max-width: 900px) {
          margin-top: 15px;
        }
        @media only screen and (max-width: 860px) {
          margin-top: 30px;
        }
        @media only screen and (max-width: 550px) {
          width: 100%;
          display: block;
          padding: 24px 0;
        }
      }
      .getStartedButton {
        background-image: linear-gradient(125deg, #4a0af5 20%, #6104e7 71%);
        border: none;
        font-weight: 500;
        margin-top: 30px;
        height: 68px;
        padding: 0 64px;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        line-height: normal;
        text-align: center;
        color: #fff;
        letter-spacing: 2.4px;

        @media only screen and (max-width: 1630px) {
          margin-top: 27px;
        }
        @media only screen and (max-width: 1500px) {
          margin-top: 24px;
        }
        @media only screen and (max-width: 1300px) {
          margin-top: 21px;
        }
        @media only screen and (max-width: 1100px) {
          margin-top: 18px;
        }
        @media only screen and (max-width: 900px) {
          margin-top: 15px;
        }
        @media only screen and (max-width: 860px) {
          margin-top: 30px;
        }
        @media only screen and (max-width: 550px) {
          width: 100%;
          display: block;
          padding: 24px 0;
        }
      }
      .borderedButton {
        border: solid 1px #fff;
        background-color: transparent;
        font-weight: bold;
        margin-top: 30px;
        height: 68px;
        padding: 0 64px;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        line-height: normal;
        text-align: center;
        color: #fff;
        letter-spacing: 2.4px;

        @media only screen and (max-width: 1630px) {
          margin-top: 27px;
        }
        @media only screen and (max-width: 1500px) {
          margin-top: 24px;
        }
        @media only screen and (max-width: 1300px) {
          margin-top: 21px;
        }
        @media only screen and (max-width: 1100px) {
          margin-top: 18px;
        }
        @media only screen and (max-width: 900px) {
          margin-top: 15px;
        }
        @media only screen and (max-width: 860px) {
          margin-top: 30px;
        }
        @media only screen and (max-width: 550px) {
          width: 100%;
          display: block;
          padding: 24px 0;
        }
      }
      .centerBorderedButton {
        border: solid 1px #fff;
        background-color: transparent;
        font-weight: bold;
        margin-top: 30px;
        height: 68px;
        padding: 0 64px;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        line-height: normal;
        text-align: center;
        color: #fff;
        letter-spacing: 2.4px;
        margin: 10px auto;

        @media only screen and (max-width: 1630px) {
          margin-top: 27px;
        }
        @media only screen and (max-width: 1500px) {
          margin-top: 24px;
        }
        @media only screen and (max-width: 1300px) {
          margin-top: 21px;
        }
        @media only screen and (max-width: 1100px) {
          margin-top: 18px;
        }
        @media only screen and (max-width: 900px) {
          margin-top: 15px;
        }
        @media only screen and (max-width: 860px) {
          margin-top: 30px;
        }
        @media only screen and (max-width: 550px) {
          width: 100%;
          display: block;
          padding: 24px 0;
        }
      }
      .apiKey {
        box-shadow: 5px 5px 9px 0 rgba(16, 8, 31, 0.3);
        border: solid 1px #fff;
        background-color: transparent;
        font-weight: bold;
      }
      .centerButton {
        margin: 0 auto;
        @media only screen and (max-width: 550px) {
          width: 100%;
        }
      }
    }
    .sizedBox {
      padding-top: 50px;
      position: relative;
    }
  }
}

// .main {
//   min-height: 100vh;
//   padding: 4rem 0;
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// }

// .footer {
//   display: flex;
//   flex: 1;
//   padding: 2rem 0;
//   border-top: 1px solid #eaeaea;
//   justify-content: center;
//   align-items: center;
// }

// .footer a {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-grow: 1;
// }

// .title a {
//   color: #0070f3;
//   text-decoration: none;
// }

// .title a:hover,
// .title a:focus,
// .title a:active {
//   text-decoration: underline;
// }

// .title {
//   margin: 0;
//   line-height: 1.15;
//   font-size: 4rem;
// }

// .title,
// .description {
//   text-align: center;
// }

// .description {
//   margin: 4rem 0;
//   line-height: 1.5;
//   font-size: 1.5rem;
// }

// .code {
//   background: #fafafa;
//   border-radius: 5px;
//   padding: 0.75rem;
//   font-size: 1.1rem;
//   font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New,
//     monospace;
// }

// .grid {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-wrap: wrap;
//   max-width: 800px;
// }

// .card {
//   margin: 1rem;
//   padding: 1.5rem;
//   text-align: left;
//   color: inherit;
//   text-decoration: none;
//   border: 1px solid #eaeaea;
//   border-radius: 10px;
//   transition: color 0.15s ease, border-color 0.15s ease;
//   max-width: 300px;
// }

// .card:hover,
// .card:focus,
// .card:active {
//   color: #0070f3;
//   border-color: #0070f3;
// }

// .card h2 {
//   margin: 0 0 1rem 0;
//   font-size: 1.5rem;
// }

// .card p {
//   margin: 0;
//   font-size: 1.25rem;
//   line-height: 1.5;
// }

// .logo {
//   height: 1em;
//   margin-left: 0.5rem;
// }

// @media (max-width: 600px) {
//   .grid {
//     width: 100%;
//     flex-direction: column;
//   }
// }

// @media (prefers-color-scheme: dark) {
//   .card,
//   .footer {
//     border-color: #222;
//   }
//   .code {
//     background: #111;
//   }
//   .logo img {
//     filter: invert(1);
//   }
// }
