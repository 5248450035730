.preCode {
  text-align: start;
  font-size: 16px;
  color: #c9cbe1;
  margin: 5px 0;
  margin-right: 50px;

  span {
    color: #90fec1;
  }
}
